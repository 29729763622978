import { FunctionComponent } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { Main } from '../../components/layout/main';
import { ContactsForm } from './contactsForm';
import { Container } from '../../components/layout/container';
// import { useLanguage } from "../../store/slices/common/selectors";
import { Loader } from '../../components/common/loader';
// import { useGetContactsQuery } from "../../store/api/contacts";
import { MarkdownParser } from '../../components/layout/textBlock';

import data from '../../translations/dictionary.json';

export const Contacts: FunctionComponent = () => {
  // const lang = useLanguage();
  // const { data } = useGetContactsQuery({ lang: lang || "en" });

  if (!data) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }
  return (
    <Main>
      <PageHeader
        subtitle={data['contacts-subtitle']}
        title={data['contacts-title']}
      >
        <MarkdownParser text={data['contacts-titleContent']} />
      </PageHeader>
      <Container>
        <ContactsForm pageType="contacts" />
      </Container>
    </Main>
  );
};