/* eslint-disable @typescript-eslint/ban-types */
import { api } from '../index';
import { ContactsResponseType } from './types';

const contactsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query<
      ContactsResponseType, 
      { lang: string; pageType?: string }
    >({
      query: ({ lang, pageType = 'contacts' }) => ({
        url: `page/${lang}/contact`,
        params: { pageType },
      }),
    }),
    sendMessage: build.mutation<
      {},
      { 
        lang: string; 
        email: string; 
        topic: string; 
        message: string; 
        pageType?: string;
      }
    >({
      query: ({ lang, topic, email, message, pageType = 'contacts' }) => ({
        url: `page/${lang}/contact`,
        method: 'POST',
        body: { topic, email, message, pageType },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetContactsQuery, useSendMessageMutation } = contactsApi;