import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import styles from './contactsForm.module.css';
import { Input } from '../../../components/common/input';
import { Select } from '../../../components/common/select';
import { Textarea } from '../../../components/common/textarea';
import { useTranslation } from '../../../translations/useTranslation';
import {
  useGetContactsQuery,
  useSendMessageMutation,
} from '../../../store/api/contacts';
import { useLanguage } from '../../../store/slices/common/selectors';
import { Translation } from '../../../translations/translation';

import dataJson from '../../../translations/dictionary.json';

// Обновленная структура ответа с ошибкой
interface ApiErrorResponse {
  status?: number;
  data?: {
    success: false;
    errors: {
      email?: string[];
      topic?: string[];
      message?: string[];
    };
  };
}

// Добавляем интерфейс для успешного ответа
interface ApiSuccessResponse {
  success: boolean;
  errors?: {
    email?: string[];
    topic?: string[];
    message?: string[];
  };
}

export interface ContactsFormProps {
  pageType?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const ContactsForm: FunctionComponent<ContactsFormProps> = ({ 
  pageType = 'contacts',
  className,
  style,
}) => {
  const lang = useLanguage();
  const { data: contactsData } = useGetContactsQuery({ 
    lang: lang || 'en',
    pageType,
  });
  const inquiryTypeText = useTranslation('Inquiry Type');
  const yourEmailText = useTranslation('Your email');
  const yourMessageText = useTranslation('Your message');

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [paymentIssue, setPaymentIssue] = useState('');

  // Fix for error property not existing on UseMutationStateResult
  const [trigger, result] = useSendMessageMutation();
  
  const { isLoading, isSuccess } = result;
  const sendError = result.error;
  const responseData = result.data as ApiSuccessResponse | undefined;

  // Получаем ошибки из ответа API
  const apiError = sendError as ApiErrorResponse | undefined;
  
  // Извлекаем объект с ошибками из ответа
  // Проверяем как обычные ошибки (код 4xx), так и ответы с кодом 200 но с success: false
  const errorData = apiError?.data?.errors || 
                   (apiError as any)?.errors || 
                   (apiError as any)?.data?.errors ||
                   // Проверяем ответ с кодом 200, но с success: false
                   (responseData && 'success' in responseData && responseData.success === false ? responseData.errors : undefined);

  useEffect(() => {
    // Очищаем форму только если успешно отправлена И success=true (или undefined)
    if (isSuccess && (!responseData || !('success' in responseData) || responseData.success !== false)) {
      setEmail('');
      setMessage('');
      setPaymentIssue('');
    }
  }, [isSuccess, responseData]);

  // Добавляем дебаг логирование для просмотра ошибок и ответов
  useEffect(() => {
    if (sendError) {
      console.error('Form submission error:', sendError);
      console.error('Extracted errors:', errorData);
    }
    
    if (responseData) {
      console.log('Response data:', responseData);
      // Если получили ответ с success: false, но с кодом 200
      if ('success' in responseData && responseData.success === false && responseData.errors) {
        console.warn('Received validation errors with status 200:', responseData.errors);
      }
    }
  }, [sendError, errorData, responseData]);

  if (!contactsData) {
    return null;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Предотвращаем переход на новую страницу
    e.stopPropagation();
    
    try {
      await trigger({
        email,
        message,
        topic: paymentIssue,
        lang: lang || 'en',
        pageType,
      }).unwrap();
    } catch (err) {
      // Ловим и обрабатываем ошибку здесь, чтобы предотвратить переход на страницу ошибки
      console.error('Caught submission error:', err);
    }
  };

  // Определяем, является ли ответ по-настоящему успешным
  const isReallySuccess = isSuccess && (!responseData || !('success' in responseData) || responseData.success !== false);
  
  return (
    <form
      className={`${styles.contactsForm} ${className || ''}`}
      style={style}
      onSubmit={handleSubmit}
    >
      <div className={styles.contactsForm__header}>
        <a
          href={`mailto:${dataJson['contacts-email']}`}
          className={styles.contactsForm__link}
        >
          <span className={styles.contactsForm__icon}>
            <svg width={20} height={16} xmlns="http://www.w3.org/2000/svg">
              <use href="#mail" />
            </svg>
          </span>
          <span>{dataJson['contacts-email']}</span>
        </a>
      </div>
      <div className={styles.contactsForm__grid}>
        {isReallySuccess ? (
          <div className={styles.contactsForm__success}>
            <Translation k={"We've received your message!"} />
          </div>
        ) : (
          <>
            <div className={styles.contactsForm__formColumn}>
              <Input
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={yourEmailText}
                errors={errorData?.email}
              />
              <Select
                options={[
                  { label: inquiryTypeText, value: '' },
                  ...Object.entries(contactsData.topics).map(([key, value]) => ({
                    label: value,
                    value: key,
                  }))
                ]}
                onChange={(event) => setPaymentIssue(event.target.value)}
                value={paymentIssue}
                errors={errorData?.topic}
              />
              <Textarea
                placeholder={yourMessageText}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                errors={errorData?.message}
              />
            </div>
            <button
              className={styles.contactsForm__action}
              aria-label={'Send'}
              disabled={!email || !paymentIssue || !message || isLoading}
              type="submit"
            >
              <span className={styles.contactsForm__actionLabel}>
                Send message
              </span>
              <svg width={61} height={61} xmlns="http://www.w3.org/2000/svg">
                <use href="#submit" />
              </svg>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.06915 15.2951L15.04 5.32422"
                  stroke="white"
                  strokeWidth="1.42441"
                  strokeLinecap="round"
                />
                <path
                  d="M5.15259 5.07422H15.3004V15.222"
                  stroke="white"
                  strokeWidth="1.42441"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </>
        )}
      </div>
    </form>
  );
};